import classnames from 'classnames'
import styled from 'styled-components'

import { BookModel } from 'components/BookModel'
import { ModelViewer } from 'components/ModelViewer'
import { useAppContext } from 'context'
import { useEffect, useRef, useState } from 'react'
import { USDZExporter } from 'three-stdlib'
import { BufferGeometry, Group, Material, Mesh, Object3D } from 'three'
import { getAdminMode } from 'storage'
import { Icon3d } from 'components/icons/Icon3d'
import { fontSize, space } from 'styles/utils'
import { MAX_WIDTH } from 'styles/theme'

const Wrapper = styled.div`
  height: 0;
  opacity: 0;
  overflow: visible;
  transition: opacity 380ms 700ms ease-in-out;
  width: 100%;
  margin: 0 auto;
  position: relative;
  max-width: ${MAX_WIDTH};

  &.show {
    opacity: 1;
  }
`

const DownloadLink = styled.a`
  position: absolute;
  top: ${space(7)};
  right: ${space(5)};
  width: ${space(10)};
  height: ${space(10)};
  display: flex;
  justify-content: center;
  .icon {
    margin: auto;
  }
  > *:not(.icon) {
    bottom: 0;
    left: ${space(2)};
    transform: translateY(${space(4)});
    font-size: ${fontSize('xxs')};
    position: absolute;
  }
`

const exporter = new USDZExporter()

const BookViewer = () => {
  const { selectedBook } = useAppContext()
  const [adminMode, setAdminMode] = useState<boolean>(getAdminMode() || false)

  // // Multiple books for animation testing
  // // const dims = [
  // //   dimensions,
  // //   { width: 120, height: 180, depth: 90 },
  // //   dimensions,
  // //   { width: 220, height: 100, depth: 40 },
  // //   dimensions,
  // //   { width: 180, height: 280, depth: 10 },
  // // ]

  // // const renderBooks = () => {
  // //   let i = 0
  // //   let position = 0
  // //   const b = []

  // //   for (i; i < dims.length; i += 1) {
  // //     b.push(
  // //       <BookModel
  // //         animate={false}
  // //         delay={i * 0.4}
  // //         key={`${src}-${i}`}
  // //         position={[
  // //           // // dims[i].width / 2 - Math.random() * 10,
  // //           // // dims[i].height,
  // //           0,
  // //           0,
  // //           position,
  // //         ]}
  // //         dimensions={dims[i]}
  // //         materials={{
  // //           cover: src,
  // //           spine,
  // //           back,
  // //         }}
  // //       />
  // //     )
  // //     position += Number(dims[i].depth) + 1
  // //   }
  // //   return b
  // // }

  const meshRef = useRef<Group>(null)
  const [downloadLink, setDownloadLink] = useState('')
  const [mesh, setMesh] = useState<Object3D | null>(null)

  useEffect(() => {
    setMesh(meshRef.current)
  }, [meshRef.current])

  useEffect(() => {
    document.addEventListener('admin-mode-change', () => {
      setAdminMode(getAdminMode() || false)
    })
  }, [])

  useEffect(() => {
    if (!adminMode) {
      setDownloadLink('')
    }
  }, [adminMode])

  useEffect(() => {
    setDownloadLink('')
    if (!adminMode) return
    if (!mesh) return
    // Clone a given mesh and its children
    const deepCloneMesh = (mesh: Object3D) => {
      const clone = mesh.clone()
      clone.traverse((child) => {
        if ((child as Mesh).isMesh) {
          const meshChild = child as Mesh
          meshChild.material = Array.isArray(meshChild.material)
            ? meshChild.material.map((material) => material.clone() as Material)
            : (meshChild.material.clone() as Material)
          meshChild.geometry = meshChild.geometry.clone()
        }
      })

      return clone
    }

    // Clone the mesh
    const cloned = deepCloneMesh(mesh)

    // Invert given geometries UV coordinates
    const invertUVs = (geometry: BufferGeometry) => {
      const uvAttribute = geometry.attributes.uv
      for (let i = 0; i < uvAttribute.count; i++) {
        uvAttribute.setY(i, 1 - uvAttribute.getY(i))
      }
      uvAttribute.needsUpdate = true
    }

    // Apply UV inversion to each geometry
    cloned.traverse((child) => {
      if ((child as Mesh).isMesh) {
        const meshChild = child as Mesh
        invertUVs(meshChild.geometry)
      }
    })

    // Export cloned mesh as a USDZ file and create a download link
    exporter.parse(cloned).then((arraybuffer) => {
      const blob = new Blob([arraybuffer], {
        type: 'application/octet-stream',
      })
      const url = URL.createObjectURL(blob)
      setDownloadLink(url)
    })
  }, [mesh, adminMode])

  return (
    <Wrapper className={classnames({ show: selectedBook })}>
      <ModelViewer showing={!!selectedBook}>
        {selectedBook && (
          <BookModel
            // //  animate={false}
            // //  delay={0.4}
            ref={meshRef}
            key={`book-model-${selectedBook.id}`}
            position={[0, 0, 0]}
            dimensions={selectedBook.dimensions}
            binding={selectedBook.binding}
            materials={{
              cover: selectedBook.image,
              spine: selectedBook.spine,
              back: selectedBook.back,
            }}
            colors={selectedBook.colors}
            title={selectedBook.shortTitle || selectedBook.title}
          />
        )}
      </ModelViewer>
      {adminMode && downloadLink && selectedBook && (
        <DownloadLink
          href={downloadLink}
          download={`${selectedBook.id}-scene.usdz`}
        >
          <Icon3d size="medium" />
          <span>.USDZ</span>
        </DownloadLink>
      )}
    </Wrapper>
  )
}

export default BookViewer
