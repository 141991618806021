import { useEffect, useMemo, useState, forwardRef, useRef } from 'react'

import { useFrame, useLoader } from '@react-three/fiber'
import {
  MeshStandardMaterial,
  BoxGeometry,
  Group,
  TextureLoader,
  WebGLRenderer,
  Color,
  DataTexture,
  CanvasTexture,
  RGBAFormat,
  Texture,
  LineSegments,
  BufferGeometry,
  Mesh,
  Float32BufferAttribute,
} from 'three'

import { Binding, BingingType } from 'types/bundle'
import { Dimensions, MaterialPaths } from 'types/model'
import { renderToCanvas } from 'utils/canvasTextRenderer'

import {
  DEFAULT_BOOK_COLOR_BG,
  DEFAULT_BOOK_COLOR_TEXT,
  DEFAULT_BOOK_DIMENSIONS,
} from '../../constants'

// TODO: Update Book viewer to use texture worker

let texturePattern: HTMLImageElement | null = null

export const loadTexturePattern = (src: string) => {
  return new Promise((resolve, reject) => {
    if (texturePattern) {
      resolve(texturePattern)
      return
    }

    const img = new Image()
    img.src = src
    img.onload = () => {
      texturePattern = img
      resolve(texturePattern)
    }
    img.onerror = reject
  })
}

const createColorTexture = (color: string) => {
  const size = 1 // For a solid color, we only need a 1x1 texture
  const colorBuffer = new Uint8Array(4) // RGBA
  const [r, g, b] = new Color(color).toArray().map((c) => Math.floor(c * 255))

  colorBuffer[0] = r
  colorBuffer[1] = g
  colorBuffer[2] = b
  colorBuffer[3] = 255

  const texture = new DataTexture(colorBuffer, size, size, RGBAFormat)
  texture.needsUpdate = true
  return texture
}

const createTextTexture = (
  text: string | undefined,
  color: string,
  textColor: string,
  dimensions: {
    width: number
    height: number
  },
  verticalText?: boolean
) => {
  const textureCanvas = document.createElement('canvas')

  const { width, height } = dimensions
  textureCanvas.width = width
  textureCanvas.height = height
  const context = textureCanvas.getContext('2d')

  // If for some reason the context is not available, return a solid color texture
  if (!context) return createColorTexture(color)

  if (texturePattern) {
    const pattern = context.createPattern(texturePattern, 'repeat')

    if (pattern) {
      context.fillStyle = pattern
      context.fillRect(0, 0, textureCanvas.width, textureCanvas.height)
    }
  }

  // Set background color
  context.globalAlpha = 0.8
  context.fillStyle = color
  context.fillRect(0, 0, width, height)
  context.globalAlpha = 1.0

  // Set text properties
  if (text) {
    // Draw text
    // If verticalText text render canvas text rotated 90 degrees
    if (verticalText) {
      context.fillStyle = color
      renderToCanvas(
        context,
        [text],
        '10px SF-Pro',
        true,
        true,
        10,
        0,
        10,
        0,
        0
      )
      context.fillStyle = textColor
      renderToCanvas(
        context,
        [text],
        '10px SF-Pro',
        true,
        true,
        10,
        0,
        10,
        0,
        -1
      )
    } else {
      context.fillStyle = color
      renderToCanvas(
        context,
        [text],
        '20px SF-Pro',
        false,
        false,
        22,
        0,
        20,
        20,
        0
      )
      context.fillStyle = textColor
      renderToCanvas(
        context,
        [text],
        '20px SF-Pro',
        false,
        false,
        22,
        0,
        20,
        20,
        1
      )
    }
  }

  // Convert canvas to texture
  const texture = new CanvasTexture(textureCanvas)
  texture.needsUpdate = true
  return texture
}

const defaultColorTexture = createColorTexture(DEFAULT_BOOK_COLOR_BG)

const useFallbackTexture = (
  url: string | null,
  color: string,
  textColor: string,
  dimensions: {
    width: number
    height: number
  },
  text?: string,
  verticalText?: boolean
) => {
  const [texture, setTexture] = useState<Texture>(defaultColorTexture)
  const loader = useMemo(() => new TextureLoader(), [])

  useEffect(() => {
    let isMounted = true
    if (!url) {
      const createdTexture = createTextTexture(
        text,
        color,
        textColor,
        dimensions,
        verticalText
      )
      setTexture(createdTexture)
    } else {
      loader.load(
        url,
        (loadedTexture) => {
          if (isMounted) {
            setTexture(loadedTexture)
          }
        },
        undefined,
        () => {
          if (isMounted) {
            const createdTexture = createTextTexture(
              text,
              color,
              textColor,
              dimensions,
              verticalText
            )
            setTexture(createdTexture)
          }
        }
      )
    }

    return () => {
      isMounted = false
    }
  }, [url, color, loader, textColor, text])

  return texture
}

type BookModelProps = {
  materials: MaterialPaths
  dimensions: Dimensions
  binding: BingingType
  position?: [number, number, number]
  rotation?: [number, number, number]
  renderer?: WebGLRenderer
  // // animate: boolean
  // // delay: number
  title: string
  colors: Array<string>
}

interface MeshWithEdgesProps {
  geometry: BufferGeometry
  material: THREE.Material | THREE.Material[]
  showEdges?: boolean
  faceIndices?: number[]
  lineColor?: number
}

const MeshWithEdges: React.FC<MeshWithEdgesProps> = ({
  geometry,
  material,
  showEdges = false,
  faceIndices = [],
  lineColor = 0x666666,
}) => {
  const meshRef = useRef<Mesh>(null)
  const edgesRef = useRef<LineSegments>(null)

  const createFaceEdgesGeometry = (
    geometry: BufferGeometry,
    faceIndices: number[]
  ): BufferGeometry => {
    const positionAttribute = geometry.getAttribute(
      'position'
    ) as Float32BufferAttribute
    const vertices = []

    for (let i = 0; i < faceIndices.length; i++) {
      const index1 = faceIndices[i]
      const index2 = faceIndices[(i + 1) % faceIndices.length]

      vertices.push(
        positionAttribute.getX(index1),
        positionAttribute.getY(index1),
        positionAttribute.getZ(index1),
        positionAttribute.getX(index2),
        positionAttribute.getY(index2),
        positionAttribute.getZ(index2)
      )
    }

    const edgesGeometry = new BufferGeometry()
    edgesGeometry.setAttribute(
      'position',
      new Float32BufferAttribute(vertices, 3)
    )
    return edgesGeometry
  }

  const faceEdgesGeometry = createFaceEdgesGeometry(geometry, faceIndices)

  useFrame(() => {
    if (meshRef.current && edgesRef.current && showEdges) {
      edgesRef.current.position.copy(meshRef.current.position)
      edgesRef.current.rotation.copy(meshRef.current.rotation)
      edgesRef.current.scale.copy(meshRef.current.scale)
    }
  })

  return (
    <>
      <mesh
        ref={meshRef}
        castShadow
        receiveShadow
        geometry={geometry}
        material={material}
      />
      {showEdges && (
        <lineSegments ref={edgesRef} geometry={faceEdgesGeometry}>
          <lineBasicMaterial color={lineColor} linewidth={2} />
        </lineSegments>
      )}
    </>
  )
}

export const BookModel = forwardRef<Group, BookModelProps>(
  (
    {
      materials,
      dimensions,
      binding,
      // animate = false,
      position = [0, 0, 0],
      rotation = [0, Math.PI, 0],
      // delay,
      renderer,
      title,
      colors,
    },
    ref
  ) => {
    // Configure dimensions
    const { height: h, width: w, depth: d } = dimensions

    // Fallback to default dimensions if not provided
    const depthBase = d || DEFAULT_BOOK_DIMENSIONS.depth
    const widthBase = w || DEFAULT_BOOK_DIMENSIONS.width
    const heightBase = h || DEFAULT_BOOK_DIMENSIONS.height

    const scale = 0.001

    const depth = depthBase * scale
    const width = widthBase * scale
    const height = heightBase * scale

    const color = (colors && colors[0]) || DEFAULT_BOOK_COLOR_BG
    const textColor = (colors && colors[1]) || DEFAULT_BOOK_COLOR_TEXT

    // Un-scaled dimensions for texture creation
    const faceDimensions = { width: widthBase, height: heightBase }
    const spineDimensions = { width: depthBase, height: heightBase, rotate: 90 }

    const { cover, back, spine } = materials
    const allowTransparent = cover === null
    // Load the pattern image
    loadTexturePattern('/assets/linen-128.jpg')

    // Load textures from material asset urls or create fallback textures
    // based on provided colors and dimensions
    const coverTexture = useFallbackTexture(
      cover,
      color,
      textColor,
      faceDimensions,
      title
    )
    const backTexture = useFallbackTexture(
      back,
      color,
      textColor,
      faceDimensions
    )
    const spineTexture = useFallbackTexture(
      spine,
      color,
      textColor,
      spineDimensions,
      title,
      true
    )
    const backingTexture = createColorTexture(color)
    const pagesTexture = useLoader(TextureLoader, '/assets/pages.png')
    const outerDepth = binding === Binding.Hardback ? 3 * scale : 0.5 * scale
    const outerDepthDouble = outerDepth * 2

    // Create and place geometry
    const { coverGeometry, backGeometry, pagesGeometry, spineGeometry } =
      useMemo(() => {
        if (
          width <= 0 ||
          height <= 0 ||
          depth <= 0 ||
          outerDepth <= 0 ||
          outerDepthDouble <= 0
        ) {
          console.warn(
            'One or more dimensions are negative, which may cause rendering issues.'
          )
        }
        // Create Geometry
        const coverGeo = new BoxGeometry(width, height, outerDepth)
        const backGeo = new BoxGeometry(width, height, outerDepth)
        const pagesGeo = new BoxGeometry(
          width - (outerDepthDouble + 0.01 * scale),
          height - (outerDepthDouble + 0.01 * scale),
          depth
        )
        const spineGeo = new BoxGeometry(
          depth + outerDepthDouble,
          height + 0.02 * scale,
          outerDepth
        )

        // Place Geometry
        coverGeo.translate(0, 0, depth / 2 + outerDepth / 2 + 0.02 * scale)
        pagesGeo.translate(-outerDepth / 4, 0, 0)
        backGeo.translate(0, 0, -depth / 2 - outerDepth / 2 - 0.02 * scale)
        spineGeo.translate(
          0,
          0,
          -width / 2 + outerDepth / 2 - (0.01 * scale) / 2
        )
        spineGeo.rotateY(Math.PI / 2)

        // Create Grouped Faces for multiple material use
        pagesGeo.addGroup(0, 26, 0)
        spineGeo.addGroup(0, 24, 0)
        spineGeo.addGroup(24, 6, 1)
        spineGeo.addGroup(30, 6, 0)

        return {
          coverGeometry: coverGeo,
          backGeometry: backGeo,
          pagesGeometry: pagesGeo,
          spineGeometry: spineGeo,
        }
      }, [width, height, depth, outerDepth, outerDepthDouble])

    // // Enable mipmaps generation for the textures.
    // // Mipmaps are smaller versions of the texture used for distant rendering, improving performance and visual quality.
    // // coverTexture.generateMipmaps = true
    // // backTexture.generateMipmaps = true
    // // spineTexture.generateMipmaps = true
    // // pagesTexture.generateMipmaps = true

    // // Set the minification filter to LinearMipmapLinearFilter for the textures.
    // // This means when the texture is minified (displayed smaller than its actual size),
    // // it uses a linear blend of the two mipmaps that most closely match the size of the pixel being rendered,
    // // leading to smoother transitions and less texture aliasing.
    // // coverTexture.minFilter = LinearMipmapLinearFilter
    // // backTexture.minFilter = LinearMipmapLinearFilter
    // // spineTexture.minFilter = LinearMipmapLinearFilter
    // // pagesTexture.minFilter = LinearMipmapLinearFilter

    // //  Set the level of anisotropic filtering for the textures.
    // //  Anisotropic filtering enhances the image quality of textures on surfaces that are far away and steeply angled with respect to the camera.
    // //  The level is set to the maximum supported by the renderer, ensuring the best possible quality.
    // //  if (renderer) {
    // //    coverTexture.anisotropy = renderer.capabilities.getMaxAnisotropy()
    // //    coverTexture.anisotropy = renderer.capabilities.getMaxAnisotropy()
    // //    backTexture.anisotropy = renderer.capabilities.getMaxAnisotropy()
    // //    spineTexture.anisotropy = renderer.capabilities.getMaxAnisotropy()
    // //    pagesTexture.anisotropy = renderer.capabilities.getMaxAnisotropy()
    // //  }

    // Create Materials
    const coverMaterial = new MeshStandardMaterial({ map: coverTexture })
    const backMaterial = new MeshStandardMaterial({ map: backTexture })
    const spineMaterial = new MeshStandardMaterial({ map: spineTexture })
    const pagesMaterial = new MeshStandardMaterial({ map: pagesTexture })
    const backingMaterial = new MeshStandardMaterial({ map: backingTexture })

    // // if (animate) {
    if (allowTransparent) {
      coverMaterial.transparent = true
      backMaterial.transparent = true
      spineMaterial.transparent = true
      backingMaterial.transparent = true

      coverMaterial.opacity = 0.8
      backMaterial.opacity = 0.8
      spineMaterial.opacity = 0.8
      backingMaterial.opacity = 0.8
    }

    // pagesMaterial.opacity = 0.6
    // // }
    // // const meshesRef = useRef<Group>(null)
    // // const startX = -10

    // Cleanup
    useEffect(() => {
      return () => {
        // Dispose geometries
        coverGeometry.dispose()
        backGeometry.dispose()
        pagesGeometry.dispose()
        spineGeometry.dispose()

        // Dispose materials
        coverMaterial.dispose()
        backMaterial.dispose()
        spineMaterial.dispose()
        pagesMaterial.dispose()

        // Dispose textures
        coverTexture.dispose()
        backTexture.dispose()
        spineTexture.dispose()
        pagesTexture.dispose()

        renderer?.dispose()
        // renderer.forceContextLoss()
        // renderer.forceContextLoss()
      }
    }, [])

    // // useEffect(() => {
    // //   if (animate) {
    // //     bookRef.current?.rotation.set(0, 0, -Math.PI / 2)
    // //     bookRef.current?.position.set(
    // //       position[0],
    // //       position[1],
    // //       position[2] + startX
    // //     )
    // //   }
    // // })

    // // useFrame(({ clock }) => {
    // //   if (!bookRef?.current || !animate) return
    // //   const elapsedTime = clock.getElapsedTime()

    // //   if (elapsedTime < delay) return

    // //   if (bookRef.current.rotation.z < 0) bookRef.current.rotation.z += 0.05

    // //   if (bookRef.current.position.z < position[2])
    // //     bookRef.current.position.z += 0.5

    // //   const increment = 0.03
    // //   coverMaterial.opacity = Math.min(coverMaterial.opacity + increment, 1)
    // //   backMaterial.opacity = Math.min(backMaterial.opacity + increment, 1)
    // //   spineMaterial.opacity = Math.min(spineMaterial.opacity + increment, 1)
    // //   pagesMaterial.opacity = Math.min(pagesMaterial.opacity + increment, 1)
    // // })

    // Vertex indices for line rendering
    const leftFaceIndices = [0, 2, 7, 5]
    const rightFaceIndices = [1, 3, 6, 4]
    const spinFaceIndices = [1, 3, 6, 4]
    return (
      <group position={position} rotation={rotation} ref={ref}>
        <MeshWithEdges
          geometry={spineGeometry}
          material={[spineMaterial, backingMaterial]}
          showEdges={allowTransparent}
          faceIndices={spinFaceIndices}
          lineColor={0xcccccc}
        />
        <MeshWithEdges
          geometry={coverGeometry}
          material={coverMaterial}
          showEdges={allowTransparent}
          faceIndices={leftFaceIndices}
          lineColor={0xcccccc}
        />
        <MeshWithEdges
          geometry={backGeometry}
          material={backMaterial}
          showEdges={allowTransparent}
          faceIndices={rightFaceIndices}
          lineColor={0xcccccc}
        />
        <MeshWithEdges
          geometry={pagesGeometry}
          material={[pagesMaterial]}
          showEdges={false}
        />
        {/* </group> */}
      </group>
    )
  }
)
